import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入eleui核心库
import ElementUI from 'element-ui';
// 引入v-charts
import VeLine from 'v-charts/lib/line.common'
import VePie from 'v-charts/lib/pie.common'
// 引入样式
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 设置v-charts
Vue.component(VeLine.name, VeLine)
Vue.component(VePie.name, VePie)

router.beforeEach((to, from, next) => {
  // ElementUI.Message.closeAll() //清除提示
  // 判断用户是否已经登录 0503
  // 0530 设置登录过期定时器
  if (to.path !== '/index') {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (!userInfo) {
      next({ path: '/index' })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.prototype.$enter = (path,callback)=>{
  document.onkeydown = function () {
    let key = window.event.keyCode;
    if (key === 13 && window.location.hash === '#'+path) {
      callback(); //方法
    }
  };
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')



